<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!--법령-->
          <c-select
            codeGroupCd="LAW_ID"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="lawId"
            label="LBL0010071"
            v-model="searchParam.lawId"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!--담당자-->
          <c-field
            type="user"
            label="LBLMANAGER"
            name="userId"
            v-model="searchParam.userId" />
        </div>
      </template>
    </c-search-box>
    <!--법령 담당자 목록-->
    <c-table
      ref="table"
      title="LBL0010104"
      tableId="table"
      :columns="grid.columns"
      :data="grid.data"
      :checkClickFlag="false"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <!--검색-->
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'users'">
          <c-tag 
            :editable="editable"
            icon="person_outline"
            itemText="userName"
            itemValue="check"
            name="users" 
            v-model="props.row.users"
            @addTag="addManager(props.row)"
            @removeTag="item => removeManager(item)"
          />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: "law-manager",
    data() {
    return {
      grid: {
        columns: [
          {
            name: 'lawName',
            field: 'lawName',
            //법령
            label: 'LBL0010071',
            align: 'left',
            style: 'width:400px',
            sortable: true,
          },
          {
            name: 'users',
            field: 'users',
            //담당자
            label: 'LBLMANAGER',
            align: 'left',
            type: 'custom',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        lawId: null,
        userId: null,
      },
      row: null,
      editable: true,
      listUrl: '',
      insertUrl: '',
      deleteUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // this.searchParam.userId = this.$store.getters.user.userId
      // url setting
      this.listUrl = selectConfig.mdm.lawManager.list.url
      this.insertUrl = transactionConfig.mdm.lawManager.insert.url;
      this.deleteUrl = transactionConfig.mdm.lawManager.delete.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    addManager(row) {
      this.row = this.$_.clone(row)
      this.popupOptions.title = 'LBL0000560'; // 사용자 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.row.users, { userId: item.userId }) === -1) {
            this.$set(item, 'lawId', this.row.lawId)
            saveData.push(item)
          }
        })
        this.$http.url = this.insertUrl;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request((_result) => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        },);
      }
    },
    removeManager(item) {
      this.$http.url = this.$format(this.deleteUrl, item.mdmLawManagerId);
      this.$http.type = 'DELETE';
      this.$http.request((_result) => {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
        this.getList();
      },);
    }
  },
};
</script>
