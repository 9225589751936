var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    codeGroupCd: "LAW_ID",
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "lawId",
                    label: "LBL0010071",
                  },
                  model: {
                    value: _vm.searchParam.lawId,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "lawId", $$v)
                    },
                    expression: "searchParam.lawId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-field", {
                  attrs: { type: "user", label: "LBLMANAGER", name: "userId" },
                  model: {
                    value: _vm.searchParam.userId,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "userId", $$v)
                    },
                    expression: "searchParam.userId",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "LBL0010104",
            tableId: "table",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            checkClickFlag: false,
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "users"
                    ? [
                        _c("c-tag", {
                          attrs: {
                            editable: _vm.editable,
                            icon: "person_outline",
                            itemText: "userName",
                            itemValue: "check",
                            name: "users",
                          },
                          on: {
                            addTag: function ($event) {
                              return _vm.addManager(props.row)
                            },
                            removeTag: (item) => _vm.removeManager(item),
                          },
                          model: {
                            value: props.row.users,
                            callback: function ($$v) {
                              _vm.$set(props.row, "users", $$v)
                            },
                            expression: "props.row.users",
                          },
                        }),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "table-button" }, [
            _c(
              "div",
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _c("c-btn", {
                      attrs: { label: "LBLSEARCH", icon: "search" },
                      on: { btnClicked: _vm.getList },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }